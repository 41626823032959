@import 'den-design-system/style.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Mulish, sans-serif';
}

input:-webkit-autofill {
  -webkit-text-fill-color: black;
}

body {
  background: #eff3fa;
  overflow: hidden;
}
body > #root {
  height: 100vh;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

@media screen and (max-width: 700px) {
  ::-webkit-scrollbar {
    width: 2px;
  }
}

::-webkit-scrollbar-track {
  /* background: #ffffff; */
}

::-webkit-scrollbar-thumb {
  background: rgb(170, 170, 170);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(110, 110, 110);
}

.commonDisplay {
  display: flex;
  justify-content: center;
  align-content: center;
}
:root {
  --toastify-color-success: #3498db !important;
}
#root > div {
  z-index: 0 !important;
}

/* .toast {
  box-sizing: border-box;
  position: absolute;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: aqua;

  flex-direction: row;
  align-items: center;
  padding: 16px !important;
  gap: 16px !important;
}

.Toastify__toast {
  min-height: 64px !important;
  margin-bottom: 10px !important;
  height: fit-content !important;
}

.Toastify__toast-container--bottom-right {
  bottom: 5em !important;
}

.Toastify__toast-body {
  color: #000411;
  font-size: 14px;
  padding-left: 20px !important;
  padding-top: 20px;
  line-height: 20px;
  padding: 0px;
  width: 100%;
  font-weight: 400;
  margin-left: 35px !important;
}

.Toastify__toast--success ::before {
  content: url('../assets/icons/successIcon.svg');
  position: absolute;
  color: #861212;
  font-size: 15px;
  font-weight: 700;
  bottom: 15px;
  left: 15px;

  padding-top: 14px !important;
}

.Toastify--animate-icon {
  display: none !important;
}

.Toastify__close-button {
  display: none !important;
}

.Toastify__toast--success {
  border: 1px solid #066cff !important;
  border-radius: 12px !important;
  box-shadow: 0px 15px 25px rgba(71, 78, 93, 0.15);
  background: #e0edff !important;
}

.Toastify__toast--warning::before {
  content: url('../assets/icons/warning.svg');
  position: absolute;
  color: #861212;
  font-size: 15px;
  font-weight: 700;
  bottom: 6.25%;
  top: 9.38%;
  left: 6.25%;
  right: 6.25%;
  padding-top: 14px !important;
} */

/* .Toastify__toast--warning::after {
  content: url('../assets/icons/close.svg');
  position: absolute;
  color: #861212;
  font-size: 15px;
  font-weight: 700;
  bottom: 25px;
  left: 250px;
  padding-top: 14px !important;
} */
/* .Toastify__toast--warning {
  border: 1px solid #e92828 !important;
  border-radius: 12px !important;
  box-shadow: 0px 15px 25px rgba(71, 78, 93, 0.15);
  background: #ffffff !important;
  margin-right: 30px !important;
}

.Toastify__toast--error::before {
  content: url('../assets/icons/warning.svg');
  position: absolute;
  color: #861212;
  font-size: 15px;
  font-weight: 700;
  bottom: 6.25%;
  top: 9.38%;
  left: 6.25%;
  right: 6.25%;
  padding-top: 14px !important;
} */

/* .Toastify__toast--error::after {
  content: url('../assets/icons/close.svg');
  position: absolute;
  color: #861212;
  font-size: 15px;
  font-weight: 700;
  bottom: 25px;
  left: 250px;
  padding-top: 14px !important;
} */
/* .Toastify__toast--error {
  border: 1px solid #e92828 !important;
  border-radius: 12px !important;
  box-shadow: 0px 15px 25px rgba(71, 78, 93, 0.15);
  background: #ffffff !important;
  margin-right: 30px !important;
} */

/* .Toastify__toast--success {
  background-color: #eaf6e4 !important;
} */

/*new toast*/
/* .Toastify__toast-container {
  padding: 0px !important;
}
.Toastify__toast {
  padding: 0px !important;
  border-radius: 0px !important;
}
.Toastify__toast-body {
  width: 100%;
  margin: 0px !important;
}
.Toastify__toast-body div:first-child {
  height: 100%;
} */
.key-value-table-hover-api-monitoring {
  &:hover {
    overflow: clip;
    white-space: normal;
    word-break: break-all;
  }
}

.modal-content-padding {
  padding: 0px !important;
}

.dds-chip-typography {
  color: white !important;
}

.rc-select-selector {
  border-radius: 0px;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.dds-select {
  width: 100% !important;
}

.input-wrapper {
  width: 100%;
}

.reference-link .input-wrapper {
  width: 100% !important;
}

.tailwind-provider .incident-add-button {
  width: 20% !important;
}

/* .dds-date-container {
  width: 100% !important;
}

.dds-date-container div {
  width: 100% !important;
} */
.override-width > div {
  width: auto !important;
}

.tailwind-table-cell > td {
  padding: 12px !important;
}

.rc-select-single .rc-select-selector .rc-select-selection-item {
  color: #414141 !important;
}

.tabs-tailwind {
  z-index: 1 !important;
}

.custom-tab .tabs-tailwind {
  justify-content: center !important;
  padding: 0px !important;
}

.custom-text .tailwind-provider {
  width: auto !important;
}

.table-width .tailwind-provider {
  width: auto !important;
}

.custom-filter > div:nth-child(2) {
  height: 32px !important;
}

.override-filter-margin .css-1n7y7g1 {
  margin-right: 0px !important;
}

.rc-select-arrow {
  padding-right: 16px;
}
.override-profile-menu .tailwind-menu-wrapper {
  padding: 12px !important;
}

.override-statusCode-center .tailwind-provider {
  display: flex !important;
  justify-content: center !important;
}
.override-status-chip .dds-chip-size-small {
  height: 24px !important;
}

.override-version .tailwind-provider {
  width: auto !important;
}
.override-overview-table {
  border-collapse: collapse;
  border-radius: 6px;
  border: solid 1px #d9e0ea !important;
}

.override-overview-table td {
  border-bottom: solid 1px #d9e0ea !important;
  padding: 12px 16px;
}

.override-overview-table tr:last-child td {
  border-bottom: none !important; /* Remove bottom border for the last row */
}

.override-overview-table tr:first-child td:first-child {
  border-top-left-radius: 6px;
}

.override-overview-table tr:first-child td:last-child {
  border-top-right-radius: 6px;
}

.override-overview-table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

.override-overview-table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.override-custom-table table {
  border-collapse: separate !important;
  border-spacing: 0 !important;
}

.override-custom-table td {
  border: solid 1px #d9e0ea !important;
  border-style: none solid solid none !important;
  padding: 12px 16px !important  ;
}
.dds-menu-rounded-rectangle {
  border-radius: 8px !important;
}
.override-status-code .dds-menu-rounded-rectangle {
  background-color: #f5f7f9 !important;
  border-radius: 8px 10px !important ;
}

.override-status-code .tailwind-menu-wrapper {
  padding: 8px 10px !important;
}

.override-custom-table tr:first-child td:first-child {
  border-top-left-radius: 6px !important;
}
.override-custom-table tr:first-child td:last-child {
  border-top-right-radius: 6px !important;
}

.override-custom-table tr:last-child td:first-child {
  border-bottom-left-radius: 6px !important;
}
.override-custom-table tr:last-child td:last-child {
  border-bottom-right-radius: 6px !important;
}

.override-custom-table tr:first-child td {
  border-top-style: solid !important;
}

.override-custom-table tr td:first-child {
  border-left-style: solid !important;
}

.tailwind-table-container {
  border: none !important;
  border-bottom: 1px solid #eeeeee !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.tailwind-table-cell-head > td {
  padding: 12px 16px !important;
}

.tailwind-table-cell-body > td {
  padding: 12px 16px !important;
}

.override-overflow > div > div > div {
  overflow-y: hidden !important;
}

.override-border-color {
  border-color: #d9e0ea !important;
}

.override-min-width .dds-button {
  min-width: 112px !important;
  font-size: 14px !important;
  justify-content: space-between !important;
}

.override-min-width-status .dds-button {
  min-width: 130px !important;
  font-size: 14px !important;
  justify-content: space-between !important;
}

.dds-table-loader {
  margin: 12px 16px !important;
}

.pagination-button-container-disabled {
  border: none !important;
}

.pagination-text {
  color: #808ea2 !important;
}

.pagination-button-container svg {
  color: #808ea2 !important;
}

.pagination-button-container-disabled svg {
  color: #808ea2 !important;
}
/* .override-padding{
  border
} */

.override-padding {
  border-radius: 8px !important;
}
.override-padding .dds-button {
  padding: 4px 12px !important;
  font-size: 14px !important;
}

.override-background-color #chip-menu-list {
  background-color: #edf5ff !important;
}

.override-tooltip-content-center {
  display: flex;
  justify-content: center;
}

.tailwind-table-cell-head > td {
  padding: 12px 16px !important;
}
.tailwind-table-container {
  border: none !important;
  border-bottom: 1px solid #eeeeee !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.tailwind-table-cell-body > td {
  padding: 12px 16px !important;
}
.rotating-loader {
  animation-duration: 0.5s !important;
}
.override-tooltip-arrow {
  opacity: unset !important;
}
.dds-typography {
  line-height: 20px !important;
}
.override-tooltip-arrow .core-styles-module_arrow__cvMwQ {
  border-right: 1px solid #d9e0ea !important;
  border-bottom: 1px solid #d9e0ea !important;
}

.tailwind-tooltip-text .dds-typography {
  color: #414141;
}
.dds-breadcrumbs {
  padding: 0px !important;
}

.rc-select-dropdown {
  border-radius: 8px;
}
.override-stepper-title .dds-vertical-stepper-title-wrapper {
  display: flex;
  max-width: 100% !important;
  width: 20% !important;
  min-width: 20% !important;
}

.override-stepper-title .dds-vertical-stepper-description-wrapper {
  max-width: 100% !important;
}

.override-copyright .tailwind-provider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto !important;
}

.override-width-dropdown .dds-menu-tailwind {
  width: max-content !important;
  min-width: max-content !important;
}
.override-chip-tailwind-provider .tailwind-provider {
  width: auto !important;
}
.react-datepicker__month-container {
  background-color: white;
}
.react-datepicker__month {
  background-color: white;
}

.override-datepicker-shape div {
  border-radius: 6px !important;
}
.override-textarea-border-radius .textarea-rectangle {
  border-radius: 6px !important;
}

.override-datepicker-shape .react-datepicker-wrapper {
  display: flex;
  .dds-checkbox-input-tailwind {
    height: 12px !important;
    width: 12px !important;
  }
  .override-refresh-button .tailwind-provider {
    display: flex;
    justify-content: end;
  }

  .override-single-log-severity .dds-button {
    height: 32px !important;
  }

  .override-single-log-severity .dds-menu-tailwind {
    border-width: 1px !important;
    width: 112px !important;
  }

  .override-single-log-status .dds-button {
    height: 32px !important;
  }

  .override-single-log-status .dds-menu-tailwind {
    border-width: 1px !important;
    width: 132px !important;
  }

  .override-checkbox-style tbody > td:first-child .tailwind-provider {
    display: flex;
    width: min-content !important;
  }
  .dds-checkbox-container {
    width: min-content !important;
  }

  .dds-checkbox-inside-container-tailwind {
    width: min-content !important;
  }
  .override-dds-chip .dds-chip {
    padding: 2px 6px !important;
  }

  .tailwind-textarea-disabled .override-text-area {
    opacity: 1 !important ;
    background-color: #ffffff !important;
  }
}

.override-on-non-prod .context-switcher-tabs-tailwind-title-active {
  background-color: #ff9900 !important;

  .override-single-log-severity .dds-button {
    height: 32px !important;
  }

  .override-single-log-severity .dds-menu-tailwind {
    border-width: 1px !important;
    width: 112px !important;
  }

  .override-single-log-status .dds-button {
    height: 32px !important;
  }

  .override-single-log-status .dds-menu-tailwind {
    border-width: 1px !important;
    width: 132px !important;
  }

  .override-checkbox-style tbody > td:first-child .tailwind-provider {
    display: flex;
    width: min-content !important;
  }
  .dds-checkbox-container {
    width: min-content !important;
  }

  .dds-checkbox-inside-container-tailwind {
    width: min-content !important;
  }
  .override-dds-chip .dds-chip {
    padding: 2px 6px !important;
  }

  .tailwind-textarea-disabled .override-text-area {
    opacity: 1 !important ;
    background-color: #ffffff !important;
  }
}

.override-dds-menu-wrapper .dds-menu-rounded-rectangle {
  background-color: #001635 !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 2px !important;
}

.override-padding-timezone
  .dds-select-component
  .rc-select-single:not(.rc-select-customize-input)
  .rc-select-selector {
  padding-left: 14px !important;
}
.override-padding-timezone
  .rc-select-selector
  .rc-select-selection-search-input {
  padding-left: 0px !important;
}
.dds-breadcrumbs {
  cursor: auto !important;
}

.relative-sandbox {
  /* background-position: center; */
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

@media (max-width: 768px) {
  .relative-sandbox {
    background-position: top center;
  }
}

/* Adjustments for small mobile screens */
@media (max-width: 480px) {
  .relative-sandbox {
    background-position: center top;
    background-size: cover;
  }
}

.table-non-interactive-member tr {
  cursor: auto;
}

.table-non-interactive .interactive-element {
  cursor: pointer;
}

.table-non-interactive tr {
  cursor: auto;
}

.table-border-override .tailwind-table-cell-body > td {
  border-bottom: 0px !important;
}

.override-table-container .tailwind-table-container {
  border: none !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.override-exception-tag-input .dds-input {
  border: none !important;
}
