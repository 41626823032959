/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.full-viewport-width {
  width: 100vw;
}
.override-height .context-switcher-tabs-tailwind-title {
  height: 22px !important;
}

.tab-child > div {
  width: 100%;
}

.custom-label .tailwind-provider {
  width: auto !important;
}

.arrow {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.custom-min-width {
  min-width: 154px !important;
}

.custom-min-width .rc-select-selector {
  border-radius: 6px;
}

.severity-min-width .rc-select-input {
  min-width: 173px !important;
}

.custom-dds-input {
  height: 32px !important;
  width: 140px !important;
}

.custom-dds-input div {
  padding: 4px 4px 4px 4px !important;
}
.custom-dds-input input {
  font-size: 20px !important;
  font-weight: 600;
}

.override-chip .dds-chip {
  height: 24px !important;
  padding: 4px 8px !important;
  gap: 0px !important;
}

.override-chip .dds-chip-typography {
  padding-left: 8px;
  padding-right: 8px;
}

.react-tooltip-arrow {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #d9e0ea !important;
  border-right: 1px solid #d9e0ea !important;
}

.styles-module_tooltip__mnnfp {
  padding: 8px 10px !important;
}

.react-tooltip {
  border: 1px solid #d9e0ea !important;
}

/* .override-tooltip #Tooltip .react-tooltip-arrow {
  border-bottom: 1px solid #d9e0ea !important;
  border-right: 1px solid #d9e0ea !important;
} */

.taliwind-tooltip-text p {
  color: #414141 !important;
}

.override-tabs .pt-\[15px\] {
  padding-top: 0px;
}

.dds-list-menu:hover {
  background-color: #f5f7f9 !important;
}

.override-menu-btn .dds-list-menu {
  min-width: 100px !important;
}
.override-button .dds-button {
  padding: 0px 12px !important;
  font-size: 14px !important;
}

.override-button .loader {
  border-width: 2px !important;
}

.override-monitor-btn .dds-button {
  padding: 4px 16px !important;
  height: 28px;
  font-weight: 500 !important;
}
.override-start-btn .dds-button {
  border-radius: 6px 0px 0px 6px !important;
}
.override-stop-btn .dds-button {
  border-radius: 0px 6px 6px 0px !important;
}
.override-pause-btn .dds-button {
  min-width: 103px !important;
}

.override-monitor-play .dds-button {
  min-width: 158px !important;
}

.override-modal-header-border .border-gray-300 {
  border-bottom: 1px solid #d9e0ea !important;
}

.override-modal-header-border .dds-modal-close-icon {
  color: #222222 !important;
}

.override-btn-min-width .dds-button {
  min-width: 120px !important;
}

.override-breadcrumbs .undefined {
  padding: 0px !important;
}

.override-switch .after\:w-\[24px\]::after {
  width: 20px;
}

.override-switch .after\:h-\[24px\]::after {
  height: 20px;
}

.override-input .gap-\[8px\] {
  gap: 0px !important;
}

.override-input ::placeholder {
  color: #bac2cd !important;
}

.dds-input {
  border: 1px solid #d9e0ea !important;
}
.dds-input-error {
  border-color: #d71414 !important;
}
.override-input-height .dds-input {
  height: 36px !important;
}
.menu-list-hover:hover {
  background-color: #f5f7f9 !important;
}

.rc-select-item-option-content {
  text-align: left !important;
}
.dds-input-field-focus {
  border: 1px solid var(--primary-color) !important;
}

.override-select .rc-select-selector {
  border-radius: 0px 6px 6px 0px !important;
}

.override-select
  .dds-select-component
  .rc-select-single:not(.rc-select-customize-input)
  .rc-select-selector {
  border: 0px !important;
  border-right: 1px solid !important;
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
  border-color: #d9e0ea !important;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

@media (max-width: 1024px) {
  .override-env-select .rc-select-input {
    min-width: 190px !important;
  }
}
.override-freq-select .rc-select-input {
  min-width: 154px !important;
}
.override-select .rc-select-input {
  min-width: 235px;
}
@media (max-width: 1024px) {
  .override-select .rc-select-input {
    min-width: 190px; /* Adjust this value as needed */
  }
}
.override-select-top
  .dds-select-component
  .rc-select-single:not(.rc-select-customize-input)
  .rc-select-selector {
  border-color: #d9e0ea !important;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
}
.override-select-top .dds-select-container {
  gap: 12px !important;
}

.override-select .rc-select-selection-placeholder {
  color: #bac2cd !important;
}
.override-select-top .rc-select-selection-placeholder {
  color: #bac2cd !important;
}
.override-env-select-popup .rc-select {
  width: 510px;
}
.override-select .rc-select-selection-item .flex-row span {
  margin-left: 0px !important;
}

.override-select-top .rc-select-selection-item .flex-row span {
  margin-left: 0px !important;
}

.override-input span {
  color: #d71414 !important;
}

.override-select-top .dds-flex span {
  color: #d71414 !important;
}
.override-breadcrumbs .dds-breadcrumbs-type-ghost {
  padding: 0px !important;
}

.overriding-chip {
  background-color: #edf5ff !important;
}

.dds-modal-custom-footer-border {
  border: 1px solid #d9e0ea !important;
}

.override-input .dds-input-container {
  min-width: 100px !important;
  gap: 12px !important;
}

.override-input .dds-input-field-wrapper {
  min-width: 50px !important;
}

.override-input-search .dds-input-field-wrapper {
  padding: 8px;
}

.override-overview-graph-tabs .context-switcher-tabs-tailwind-title {
  height: 22px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.override-overview-graph-tabs .mt-4 {
  margin-top: 0px !important;
}
.override-overview-graph-tabs .context-switcher-tab-tailwind-container {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.override-view-more-tabs .context-switcher-tabs-tailwind-title {
  height: 22px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.override-view-more-tabs .context-switcher-tab-tailwind-container {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.override-view-more-tabs .mt-4 {
  margin-top: 0px !important;
}

.override-project-icon img {
  margin-left: 0px !important;
}

.override-member-role-select .rc-select {
  width: 708px !important;
}
.override-project-select .rc-select {
  min-width: 213px !important;
}

.override-login-btn-width .dds-button {
  min-width: 599px !important;
}
.override-login-btn-width .dds-button-prefix-icon {
  width: 20px !important;
  height: 20px !important;
}

.override-select-validation .dds-justify-start.dds-flex {
  margin-top: -1px !important;
}

.override-model-min-width .dds-modal-wrapper {
  min-width: 50% !important;
}

.override-model-min-height .dds-modal-wrapper {
  min-width: 50% !important;
  height: 50vh !important;
}
.override-model-verify-min-width .dds-modal-wrapper {
  min-width: 40% !important;
}

.override-select-width .rc-select-input {
  min-width: 200px !important;
}
.override-filter-option .rc-select-show-search {
  border: 0px !important;
}
.dds-tailwind-radio-container {
  display: flex;
  flex-direction: row;
  gap: 0px !important;
  align-items: center;
  padding-inline-end: 0px !important;
}

.font-weight-800-important .dds-typography-font-Weight-bold {
  font-weight: 800 !important;
}

.override-error-message .dds-error-message-input {
  margin-top: 0px !important;
}
.react-datepicker-popper {
  width: auto !important;
}

.override-select-top-border-remove
  .dds-select-component
  .rc-select-single:not(.rc-select-customize-input)
  .rc-select-selector {
  border-color: #ffffff !important;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
}

.override-select-top-select-graph .rc-select-input {
  min-width: 148px !important;
}

.override-dds-input-field-incident-page .dds-input-field-wrapper {
  height: 36px !important;
}
